$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$(document).ready(function(){

    var laravel = {
        initialize: function() {
            this.registerEvents();
        },

        registerEvents: function() {
            // register method handlers
            $(document).on('click', 'a[data-method]', this.handleMethod);
        },

        handleMethod: function(e) {
            e.preventDefault();
            var link = $(this);
            var httpMethod = link.data('method').toUpperCase();
            // If the data-method attribute is not PUT or DELETE or POST,
            // then we don't know what to do. Just ignore.
            if ( $.inArray(httpMethod, ['PUT', 'DELETE', 'POST']) === -1 ) {
                return;
            }

            // Allow user to optionally provide data-confirm="Are you sure?"
            if ( link.data('confirm') ) {
                laravel.verifyConfirm(link, laravel.submitLink);
            } else {
                laravel.submitLink(link);
            }
        },

        verifyConfirm: function(link, callback) {
            var icon = link.data('icon') || '';
            var confirmationTitle = link.data('confirmation-title') || 'Please Confirm';
            var confirmationMessage = link.data('confirm');
            var confirmationButtonText = link.data('confirmation-button-text') || 'Confirm';

            return BootstrapDialog.show({
                title: '<i class="fa fa-question-circle"></i> ' + confirmationTitle,
                type: BootstrapDialog.TYPE_PRIMARY,
                message: confirmationMessage,
                spinicon: 'fa fa-refresh fa-spin',
                buttons: [
                    {
                        label: confirmationButtonText,
                        cssClass: 'btn-primary',
                        autospin: true,
                        action: function(dialog) {
                            callback(link);
                        }
                    },
                    {
                        label: 'Cancel',
                        action: function(dialog) {
                            dialog.close();
                        }
                    }
                ]
            });
        },

        submitLink: function(link) {
            var form = laravel.createForm(link);
            form.submit();
        },

        createForm: function(link) {
            var form =
                $('<form>', {
                    'method': 'POST',
                    'action': link.attr('href')
                });

            var token =
                $('<input>', {
                    'type': 'hidden',
                    'name': '_token',
                    'value': $('meta[name=csrf-token]').attr("content")
                });

            var hiddenInput =
                $('<input>', {
                    'name': '_method',
                    'type': 'hidden',
                    'value': link.data('method')
                });

            return form.append(token, hiddenInput)
                .appendTo('body');
        }
    };
    laravel.initialize();
    console.log('application event handlers registered!');

    /** datatables provisioning **/

    // supress alerts of error messages
    $.fn.dataTableExt.sErrMode = "console";

    // global datatables defaults
    // TODO - make this better
    //     $.fn.dataTable.defaults = {
    //         dataType: 'json',
    //         type: 'POST',
    //         timeout: 15000,
    //         error: function (xhr, txtStatus, err) {
    //             console.dir(xhr);
    //             if(txtStatus === 'timeout') {
    //                 alert('The server is taking too long to respond');
    //             } else {
    //                 alert('An error has occured on the server, please try again.');
    //             }
    //         }
    //     };
});

// session storage mock if unsupported
if(typeof(sessionStorage) == 'undefined') {
    sessionStorage = {
        getItem: function(){},
        setItem: function(){}
    }
}
